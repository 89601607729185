import './App.css';
import EasyFit from "./easy-fit/easy-fit.js";
//import Highcharts, { chart } from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import export_data from 'highcharts/modules/export-data';
import offline_exporting from 'highcharts/modules/offline-exporting';
import create_chart from "./create_chart.js";
import Highcharts from 'highcharts/highstock'
//import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useState } from 'react'

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarActionItem,
  TopAppBarTitle,
  TopAppBarFixedAdjust
} from '@rmwc/top-app-bar';

import '@rmwc/top-app-bar/styles';

exporting(Highcharts);
export_data(Highcharts);
offline_exporting(Highcharts);

function App() {

  const [chartOptions, setChartOptions] = useState({
    rangeSelector: {
      enabled: false
    },
    chart: {
      zoomType: 'x',
      animation: false,
      style: {
        fontFamily: '"Roboto", Verdana, Arial, Helvetica, sans-serif',
      }
    },
    exporting: {
      csv: {
          dateFormat: '%Y-%m-%d %H:%M:%S.%L'
      }
    },
    plotOptions: {
      line: {
        gapSize: 1,
        gapUnit: 'relative'
      }
    },
    navigation: {
      buttonOptions: {
          enabled: true
      }
    },
    legend: {
      enabled: true,
      symbolPadding: 0,
      symbolWidth: 0,
      symbolHeight: 0,
      squareSymbol: false
    },
    xAxis: {
      ordinal: false
    },
    scrollbar: {
      enabled: true
    },
    series: []
  });

  const updateSeries = (newSeries) => {
    let currentSeries = chartOptions.series;
    for (var j = 0; j < newSeries.length; j++){
      currentSeries.push(newSeries[j]);
    }
    setChartOptions({ 
      series: currentSeries
    });
  }

  const process = (event) => {

    const files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      var file = event.target.files[i];

      console.log(file.name);

      //let filename = file.name;

      var reader = new FileReader();

      reader.onloadend = function() {
  
        // Create a FitParser instance (options argument is optional)
        var easyFit = new EasyFit({//var fitParser = new FitParser({
          force: true,
          speedUnit: 'km/h',
          lengthUnit: 'km',
          temperatureUnit: 'celcius',
          elapsedRecordField: true,
          mode: 'list',//mode: 'cascade',
        });
  
        // Parse your file
        easyFit.parse(this.result, function (error, data) {
          // Handle result of parse method
          if (error) {
            console.log(error);
          } else {
            const series = create_chart(data);
            updateSeries(series);
          }
        });
      };
  
      reader.readAsArrayBuffer(file)
    }

    // Plugin to only run export-data on the visible range
    (function(H) {
      H.wrap(H.Chart.prototype, 'getDataRows', function(proceed, multiLevelHeaders) {
          var rows = proceed.call(this, multiLevelHeaders),
              xMin = this.xAxis[0].min,
              xMax = this.xAxis[0].max;

          rows = rows.filter(function(row) {
              return typeof row.x !== 'number' || (row.x >= xMin && row.x <= xMax);
          });

          return rows;
      });
    }(Highcharts));
  }

  return (

    <div className="App">
      <TopAppBar>
        <TopAppBarRow>
          <TopAppBarSection>
            <TopAppBarTitle>.fit Viewer</TopAppBarTitle>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
      <div style={{'padding': 20}}>
      <input type="file" onChange={process} multiple></input>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        constructorType={'stockChart'}
      />
      {/*<div id="container" style={{"height": "600px", "minWidth": "310px"}}>
      </div>*/}
    </div>
  );
}

export default App;
