'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addEndian = addEndian;
exports.readRecord = readRecord;
exports.getArrayBuffer = getArrayBuffer;
exports.calculateCRC = calculateCRC;

var _fit = require('./fit');

var _messages = require('./messages');

function addEndian(littleEndian, bytes) {
  var result = 0;
  if (!littleEndian) bytes.reverse();
  for (var i = 0; i < bytes.length; i++) {
    result += bytes[i] << (i << 3) >>> 0;
  }

  return result;
}

/*function readData(blob, fDef, startIndex) {
  if (fDef.endianAbility === true) {
    var temp = [];
    var uint32Rep;
    for (var i = 0; i < fDef.size; i++) {
      temp.push(blob[startIndex + i]);
    }

    uint32Rep = addEndian(fDef.littleEndian, temp);

    if (fDef.dataType === 'sint32') {
      return uint32Rep >> 0;
    }

    return uint32Rep;
  }
  return blob[startIndex];
}*/

function getBaseTypeSize(type) {
  switch (type) {
    case 'enum':
    case 'sint8':
    case 'uint8':
    case 'uint8z':
    case 'byte':
    case 'string':
      return 1;
    case 'sint16':
    case 'uint16':
    case 'uint16z':
      return 2;
    case 'sint32':
    case 'uint32':
    case 'uint32z':
    case 'float32':
      return 4;
    case 'sint64':
    case 'uint64':
    case 'uint64z':
    case 'float64':
      return 8;
    default:
      return 0;
  }
}

function getData(data, dataType, startIndex, littleEndian) {

  switch (dataType) {
    case 'enum':
    case 'uint8':
    case 'uint8z':
    case 'byte':
      return data.getUint8(startIndex, littleEndian);
    case 'sint8':
    case 'string':
      return data.getInt8(startIndex, littleEndian);
    case 'uint16':
    case 'uint16z':
      return data.getUint16(startIndex, littleEndian);
    case 'sint16':
      return data.getInt16(startIndex, littleEndian);
    case 'uint32':
    case 'uint32z':
      return data.getUint32(startIndex, littleEndian);
    case 'sint32':
      return data.getInt32(startIndex, littleEndian);
    case 'float32':
      return data.getFloat32(startIndex, littleEndian);
    case 'uint64':
    case 'uint64z':
      return data.getUint64(startIndex, littleEndian);
    case 'sint64':
      return data.getInt64(startIndex, littleEndian);
    case 'float64':
      return data.getFloat64(startIndex, littleEndian);
    default:
      break;
  }
}

function formatByType(data, type, scale, offset) {
  switch (type) {
    case 'date_time':
      return new Date(data * 1000 + 631065600000);//631062000000
    case 'sint32':
    case 'sint16':
      //return data * _fit.FIT.scConst;
    case 'uint32':
    case 'uint16':
      return scale ? data / scale + offset : data;
    default:
      if (_fit.FIT.types[type]) {
        return _fit.FIT.types[type][data];
      }
      return data;
  }
}

function readData(data, fDef, startIndex, type, scale, offset) {
  if (fDef.fieldValueNo > 1) {
    let temp = [];
    for (let i = 0; i < fDef.fieldValueNo; i++) {
      temp.push(formatByType(getData(data, fDef.dataType, startIndex, fDef.littleEndian), type, scale, offset));
      startIndex += fDef.baseTypeSize;
    }
    return temp;
  } else {
    return formatByType(getData(data, fDef.dataType, startIndex, fDef.littleEndian), type, scale, offset);
  }
}

function convertTo(data, unitsList, speedUnit) {
  var unitObj = _fit.FIT.options[unitsList][speedUnit];
  return unitObj ? data * unitObj.multiplier + unitObj.offset : data;
}

function applyOptions(data, field, options) {
  switch (field) {
    case 'speed':
    case 'enhanced_speed':
    case 'vertical_speed':
    case 'avg_speed':
    case 'max_speed':
    case 'speed_1s':
    case 'ball_speed':
    case 'enhanced_avg_speed':
    case 'enhanced_max_speed':
    case 'avg_pos_vertical_speed':
    case 'max_pos_vertical_speed':
    case 'avg_neg_vertical_speed':
    case 'max_neg_vertical_speed':
      return convertTo(data, 'speedUnits', options.speedUnit);
    case 'distance':
    case 'total_distance':
    case 'enhanced_avg_altitude':
    case 'enhanced_min_altitude':
    case 'enhanced_max_altitude':
    case 'enhanced_altitude':
    case 'height':
    case 'odometer':
    case 'avg_stroke_distance':
    case 'min_altitude':
    case 'avg_altitude':
    case 'max_altitude':
    case 'total_ascent':
    case 'total_descent':
    case 'altitude':
    case 'cycle_length':
    case 'auto_wheelsize':
    case 'custom_wheelsize':
    case 'gps_accuracy':
      return convertTo(data, 'lengthUnits', options.lengthUnit);
    case 'temperature':
    case 'avg_temperature':
    case 'max_temperature':
      return convertTo(data, 'temperatureUnits', options.temperatureUnit);
    default:
      return data;
  }
}

function readRecord(blob, messageTypes, startIndex, options, startDate) {
  var recordHeader = blob.getUint8(startIndex);//blob[startIndex];
  var localMessageType = recordHeader & 15;

  if ((recordHeader & 64) === 64) {
    // is definition message
    // startIndex + 1 is reserved

    var lEnd = blob.getUint8(startIndex + 2) === 0;//var lEnd = blob[startIndex + 2] === 0;
    var mTypeDef = {
      littleEndian: lEnd,
      globalMessageNumber: addEndian(lEnd, [blob.getUint8(startIndex + 3), blob.getUint8(startIndex + 4)]),
      numberOfFields: blob.getUint8(startIndex + 5),//blob[startIndex + 5],
      fieldDefs: []
    };

    var _message = (0, _messages.getFitMessage)(mTypeDef.globalMessageNumber);

    for (var i = 0; i < mTypeDef.numberOfFields; i++) {
      var fDefIndex = startIndex + 6 + i * 3;
      var baseType = blob.getUint8(fDefIndex + 2);//blob[fDefIndex + 2];

      var _message$getAttribute = _message.getAttributes(blob.getUint8(fDefIndex));//_message.getAttributes(blob[fDefIndex]);

      var field = _message$getAttribute.field;
      var type = _message$getAttribute.type;

      var fDef = {
        type: type,
        fDefNo: blob.getUint8(fDefIndex),
        size: blob.getUint8(fDefIndex + 1),
        endianAbility: (baseType & 128) === 128,
        littleEndian: lEnd,
        baseTypeNo: baseType & 15,
        baseTypeSize: getBaseTypeSize(_fit.FIT.types.fit_base_type[baseType]),
        name: field,
        dataType: _fit.FIT.types.fit_base_type[baseType],//(0, _messages.getFitMessageBaseType)(baseType & 15),
        fieldValueNo: blob.getUint8(fDefIndex + 1)/getBaseTypeSize(_fit.FIT.types.fit_base_type[baseType])
      };


      mTypeDef.fieldDefs.push(fDef);
    }
    messageTypes[localMessageType] = mTypeDef;
    //console.log(_message);
    return {
      messageType: 'fieldDescription',
      nextIndex: startIndex + 6 + mTypeDef.numberOfFields * 3
    };
  }

  var messageType = void 0;

  if (messageTypes[localMessageType]) {
    messageType = messageTypes[localMessageType];
  } else {
    messageType = messageTypes[0];
  }

  // TODO: handle compressed header ((recordHeader & 128) == 128)

  // uncompressed header
  var messageSize = 0;
  var readDataFromIndex = startIndex + 1;
  var fields = {};
  var message = (0, _messages.getFitMessage)(messageType.globalMessageNumber);

  for (var _i = 0; _i < messageType.fieldDefs.length; _i++) {
    var _fDef = messageType.fieldDefs[_i];
    var _message$getAttribute2 = message.getAttributes(_fDef.fDefNo);

    var field = _message$getAttribute2.field;
    var type = _message$getAttribute2.type;
    var scale = _message$getAttribute2.scale;
    var offset = _message$getAttribute2.offset;

    var data = readData(blob, _fDef, readDataFromIndex, type, scale, offset);

    if (field !== 'unknown' && field !== '' && field !== undefined) {
      fields[field] = applyOptions(data, field, options);//fields[field] = applyOptions(formatByType(data, type, scale, offset), field, options);
    }

    if (message.name === 'record' && options.elapsedRecordField) {
      fields.elapsed_time = (fields.timestamp - startDate) / 1000;
    }
    readDataFromIndex += _fDef.size;
    messageSize += _fDef.size;
  }

  var result = {
    messageType: message.name,
    nextIndex: startIndex + messageSize + 1,
    message: fields
  };

  return result;
}

function getArrayBuffer(buffer) {
  if (buffer instanceof ArrayBuffer) {
    return buffer;
  }
  var ab = new ArrayBuffer(buffer.length);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
}

function calculateCRC(data, start, end) {
  var crcTable = [0x0000, 0xCC01, 0xD801, 0x1400, 0xF001, 0x3C00, 0x2800, 0xE401, 0xA001, 0x6C00, 0x7800, 0xB401, 0x5000, 0x9C01, 0x8801, 0x4400];

  var crc = 0;
  for (var i = start; i < end; i++) {
    var byte = data.getUint8(i);//blob[i];
    var tmp = crcTable[crc & 0xF];
    crc = crc >> 4 & 0x0FFF;
    crc = crc ^ tmp ^ crcTable[byte & 0xF];
    tmp = crcTable[crc & 0xF];
    crc = crc >> 4 & 0x0FFF;
    crc = crc ^ tmp ^ crcTable[byte >> 4 & 0xF];
  }

  return crc;
}
