function processData(dataArray, keys) {

  let result = {};

  const dataArrayLength = dataArray.length;

  let previousSampleTimestamp = dataArray[0].timestamp.getTime() + dataArray[0].timestamp_ms;

  for (let i = 0; i < dataArrayLength; i++) {
    const message = dataArray[i];
    const timestamp = message.timestamp.getTime() + message.timestamp_ms;

    const sampleTimeOffset = message.sample_time_offset;
    const sampleTimeOffsetLength = sampleTimeOffset.length;

    for (let j = 0; j < sampleTimeOffsetLength; j++) {
      const sampleTimestamp = timestamp + sampleTimeOffset[j];
      const diff = (sampleTimestamp - previousSampleTimestamp)/10;
      if (diff > 1) {
        for (let k = 1; k < diff; k++) {
          const newTimestamp = previousSampleTimestamp + k * 10;
          keys.forEach(key => {
            if (!result[key]) result[key] = [];
            result[key].push([newTimestamp, 0]);
          });
        }
      }
      if (sampleTimestamp > previousSampleTimestamp) {

        keys.forEach(key => {
          if (!result[key]) result[key] = [];
          result[key].push([sampleTimestamp, message[key][j]]);
        });

        previousSampleTimestamp = sampleTimestamp;
      }
    }
  }
  return result;
}


export default function create_chart(data){

  const series = [];

  let serialNumber;

  if (data.file_id) {
    serialNumber = data.file_id.serial_number;
  }

  // Records

  let record_power = [];
  let record_cadence = [];

  if (data.records && data.records.length > 0) {
    const records = data.records;
    const recordsLength = records.length;
    for (let i = 0; i < recordsLength; i++) {
      const message = records[i];
      const timestamp = message.timestamp.getTime();
      record_power.push([timestamp, message.power]);
      record_cadence.push([timestamp, message.cadence]);
    }
  }

  // Raw Data

  let strain_a_one = [];
  let strain_b_one = [];
  let strain_c_one = [];
  let strain_a_two = [];
  let strain_b_two = [];
  let strain_c_two = [];

  let gyro_x = [];
  let gyro_y = [];
  let gyro_z = [];

  if (data.raw_data && data.raw_data.length > 0) {
    const rawData = data.raw_data;
    const rawDataLength = rawData.length;
    let previous_sample_timestamp = 0;
    for (let i = 0; i < rawDataLength; i++) {
      const message = rawData[i];
      const timestamp = message.timestamp.getTime() + message.timestamp_ms;
      const sampleTimeOffset = message.sample_time_offset;
      const sampleTimeOffsetLength = sampleTimeOffset.length;
      for (let j = 0; j < sampleTimeOffsetLength; j++) {
        const sample_timestamp = timestamp + sampleTimeOffset[j];
        if (sample_timestamp > previous_sample_timestamp) {
          strain_a_one.push([sample_timestamp, message.strain_a[j]]); // right
          strain_b_one.push([sample_timestamp, message.strain_b[j]]); // right
          strain_c_one.push([sample_timestamp, message.strain_c[j]]); // right
          strain_a_two.push([sample_timestamp, message.strain_d[j]]); // left
          strain_b_two.push([sample_timestamp, message.strain_e[j]]); // left
          strain_c_two.push([sample_timestamp, message.strain_f[j]]); // left
          gyro_x.push([sample_timestamp, message.gyro_x[j]]);
          gyro_y.push([sample_timestamp, message.gyro_y[j]]);
          gyro_z.push([sample_timestamp, message.gyro_z[j]]); // left
          previous_sample_timestamp = sample_timestamp;
        }
      }
    }

    series.push(
      {type: 'line', name: `#${serialNumber} - Strain A1`, data: strain_a_one, showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Strain B1`, data: strain_b_one, showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Strain C1`, data: strain_c_one, showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Strain A2`, data: strain_a_two, showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Strain B2`, data: strain_b_two, showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Strain C2`, data: strain_c_two, showInNavigator: true});
  }
        
  // Strain Data

  let channel_N = [];
  let channel_O = [];

  if (data.strain_data && data.strain_data.length > 0) {
    const strainData = data.strain_data;
    const strainDataLength = strainData.length;
    let previous_sample_timestamp = 0;
    for (let i = 0; i < strainDataLength; i++) {
      const message = strainData[i];
      const timestamp = message.timestamp.getTime() + message.timestamp_ms;
      const sampleTimeOffset = message.sample_time_offset;
      const sampleTimeOffsetLength = sampleTimeOffset.length;
      for (let j = 0; j < sampleTimeOffsetLength; j++) {
        const sample_timestamp = timestamp + sampleTimeOffset[j];
        if (sample_timestamp > previous_sample_timestamp) {
          channel_N.push([sample_timestamp, message.channel_N[j]]);
          channel_O.push([sample_timestamp, message.channel_O[j]]);
          previous_sample_timestamp = sample_timestamp;
        }
      }
    }

    series.push(
      {type: 'line', name: `#${serialNumber} - Channel N`, data: channel_N, showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Channel O`, data: channel_O, showInNavigator: true});
  }
  

  // Shaft Angle
  if (data.shaft_angle && data.shaft_angle.length > 0) {
    const result = processData(data.shaft_angle, ['angle_x', 'angle_y', 'angle_z']);
  }

  // Blade Velocity
  if (data.blade_velocity && data.blade_velocity.length > 0) {
    const result = processData(data.blade_velocity, ['blade_left_x', 'blade_left_y', 'blade_left_z', 'blade_right_x', 'blade_right_y', 'blade_right_z']);
  }
  
  // Force - Left Stroke
  if (data.hand_force_left && data.hand_force_left.length > 0) {
    const result = processData(data.hand_force_left, ['force_top', 'force_bottom', 'resultant_angle']);
    series.push(
      {type: 'line', name: `#${serialNumber} - Hand Force (Left Stroke)`},
      {type: 'line', name: `#${serialNumber} - Hand Force Top (Left Stroke)`, data: result['force_top'], linkedTo: ':previous', showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Hand Force Bottom (Left Stroke)`, data: result['force_bottom'], linkedTo: ':previous', showInNavigator: true});
  }

  // Force - Right Stroke
  if (data.hand_force_right && data.hand_force_right.length > 0) {
    const result = processData(data.hand_force_right, ['force_top', 'force_bottom', 'resultant_angle']);
    series.push(
      {type: 'line', name: `#${serialNumber} - Hand Force (Right Stroke)`},
      {type: 'line', name: `#${serialNumber} - Hand Force Top (Right Stroke)`, data: result['force_top'], linkedTo: ':previous', showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Hand Force Bottom (Right Stroke)`, data: result['force_bottom'], linkedTo: ':previous', showInNavigator: true});
  }

  // Power - Left Stroke
  if (data.hand_power_left && data.hand_power_left.length > 0) {
    const result = processData(data.hand_power_left, ['power_top', 'power_bottom']);
    series.push(
      {type: 'line', name: `#${serialNumber} - Hand Power (Left Stroke)`},
      {type: 'line', name: `#${serialNumber} - Hand Power Top (Left Stroke)`, data: result['power_top'], linkedTo: ':previous', showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Hand Power Bottom (Left Stroke)`, data: result['power_bottom'], linkedTo: ':previous', showInNavigator: true});
  }

  // Power - Right Stroke
  if (data.hand_power_right && data.hand_power_right.length > 0) {
    const result = processData(data.hand_power_right, ['power_top', 'power_bottom']);
    series.push(
      {type: 'line', name: `#${serialNumber} - Hand Power (Right Stroke)`},
      {type: 'line', name: `#${serialNumber} - Hand Power Top (Right Stroke)`, data: result['power_top'], linkedTo: ':previous', showInNavigator: true},
      {type: 'line', name: `#${serialNumber} - Hand Power Bottom (Right Stroke)`, data: result['power_bottom'], linkedTo: ':previous', showInNavigator: true});
  }

  // Quaternion

  let quat_w = [];
  let quat_x = [];
  let quat_y = [];
  let quat_z = [];

  let quat_gravity_x = [];
  let quat_gravity_y = [];
  let quat_gravity_z = [];
  let quat_gravity_magnitude = [];
  let quat_shaft_angle_y = [];

  if (data.quaternion_data && data.quaternion_data.length > 0) {
    const quaternionData = data.quaternion_data;
    const quaternionDataLength = quaternionData.length;
    for (let i = 0; i < quaternionDataLength; i++) {
      const message = quaternionData[i];
      const timestamp = message.timestamp.getTime() + message.timestamp_ms;
      const sampleTimeOffset = message.sample_time_offset;
      const sampleTimeOffsetLength = sampleTimeOffset.length;
      for (let j = 0; j < sampleTimeOffsetLength; j++) {
        const sample_timestamp = timestamp + sampleTimeOffset[j];
        const quat_w_value = message.quat_w[j];
        const quat_x_value = message.quat_x[j];
        const quat_y_value = message.quat_y[j];
        const quat_z_value = message.quat_z[j];
        quat_w.push([sample_timestamp, quat_w_value]);
        quat_x.push([sample_timestamp, quat_x_value]);
        quat_y.push([sample_timestamp, quat_y_value]);
        quat_z.push([sample_timestamp, quat_z_value]);
        const gravity_vector_x = 2 * (quat_x_value * quat_z_value) - 2 * (quat_w_value * quat_y_value);
        const gravity_vector_y = 2 * (quat_y_value * quat_z_value) + 2 * (quat_x_value * quat_w_value);
        const gravity_vector_z = quat_z_value * quat_z_value - quat_y_value * quat_y_value - quat_x_value * quat_x_value + quat_w_value * quat_w_value;
        const gravity_magnitude = Math.sqrt(gravity_vector_x * gravity_vector_x + gravity_vector_y * gravity_vector_y + gravity_vector_z * gravity_vector_z);
        quat_gravity_x.push([sample_timestamp, gravity_vector_x]);
        quat_gravity_y.push([sample_timestamp, gravity_vector_y]);
        quat_gravity_z.push([sample_timestamp, gravity_vector_z]);
        quat_gravity_magnitude.push([sample_timestamp, gravity_magnitude]);
        quat_shaft_angle_y.push([sample_timestamp, Math.acos(gravity_vector_y) * (180 / Math.PI)]);
      }
    }
  }

  return series;
}